@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [color-theme="light"] {
        --color-primary: #1E62E8;
        --color-primary-1: #F4F7FE;
        --color-primary-2: #E9EFFD;
        --color-primary-3: #D2E0FA;
        --color-primary-4: #AEC7F7;
        --color-primary-5: #78A1F1;
        --color-primary-6: #4B81ED;
        --color-primary-7: #1E62E8;
        --color-primary-8: #1B58D1;
        --color-primary-9: #184EBA;
        --color-primary-10: #123B8B;
        --color-primary-11: #0C275D;
        --color-primary-12: #06142E;
        --color-secondary: #66BE5A;
        --color-secondary-1: #F7FCF7;
        --color-secondary-2: #F0F8EF;
        --color-secondary-3: #E0F2DE;
        --color-secondary-4: #C8E8C4;
        --color-secondary-5: #A3D89C;
        --color-secondary-6: #85CB7B;
        --color-secondary-7: #66BE5A;
        --color-secondary-8: #5CAB51;
        --color-secondary-9: #529848;
        --color-secondary-10: #3D7236;
        --color-secondary-11: #294C24;
        --color-secondary-12: #142612;
        --color-black: #7A808D;
        --color-black-1: #F8F9F9;
        --color-black-2: #F2F2F4;
        --color-black-3: #E4E6E8;
        --color-black-4: #CFD1D6;
        --color-black-5: #B0B3BB;
        --color-black-6: #9599A4;
        --color-black-7: #7A808D;
        --color-black-8: #6E737F;
        --color-black-9: #626671;
        --color-black-10: #494D55;
        --color-black-11: #313338;
        --color-black-12: #181A1C;
        --color-warning-red: #F4304A;
        --color-warning-red-1: #FEF5F6;
        --color-warning-red-2: #FEEAED;
        --color-warning-red-3: #F88392;
        --color-warning-red-4: #F6596E;
        --color-warning-red-5: #F4304A;
        --color-warning-red-6: #DC2B43;
        --color-warning-orange: #F87A26;
        --color-warning-orange-1: #FFF8F4;
        --color-warning-orange-2: #FEF2E9;
        --color-warning-orange-3: #FBAF7D;
        --color-warning-orange-4: #F99551;
        --color-warning-orange-5: #F87A26;
        --color-warning-orange-6: #DF6E22;
        --color-warning-yellow: #FAB830;
        --color-warning-yellow-1: #FFFBF5;
        --color-warning-yellow-2: #FFF8EA;
        --color-warning-yellow-3: #FCD483;
        --color-warning-yellow-4: #FBC659;
        --color-warning-yellow-5: #FAB830;
        --color-warning-yellow-6: #E1A52B;
        --color-warning-green: #24D066;
        --color-warning-green-1: #F4FDF7;
        --color-warning-green-2: #E9FAF0;
        --color-warning-green-3: #7CE3A3;
        --color-warning-green-4: #50D985;
        --color-warning-green-5: #24D066;
        --color-warning-green-6: #20BB5C;
        --color-white: #FFFFFF;
    }

    [color-theme="dark"] {
        --color-primary: #1E62E8;
        --color-primary-1: #0d1117;
        --color-primary-2: #0f1317;
        --color-primary-3: #D2E0FA;
        --color-primary-4: #AEC7F7;
        --color-primary-5: #78A1F1;
        --color-primary-6: #4B81ED;
        --color-primary-7: #1E62E8;
        --color-primary-8: #1B58D1;
        --color-primary-9: #184EBA;
        --color-primary-10: #123B8B;
        --color-primary-11: #0C275D;
        --color-primary-12: #06142E;
        --color-secondary: #66BE5A;
        --color-secondary-1: #F7FCF7;
        --color-secondary-2: #F0F8EF;
        --color-secondary-3: #E0F2DE;
        --color-secondary-4: #C8E8C4;
        --color-secondary-5: #A3D89C;
        --color-secondary-6: #85CB7B;
        --color-secondary-7: #66BE5A;
        --color-secondary-8: #5CAB51;
        --color-secondary-9: #529848;
        --color-secondary-10: #3D7236;
        --color-secondary-11: #294C24;
        --color-secondary-12: #142612;
        --color-black: #c9d1d9;
        --color-black-1: #F8F9F9;
        --color-black-2: #F2F2F4;
        --color-black-3: #E4E6E8;
        --color-black-4: #CFD1D6;
        --color-black-5: #B0B3BB;
        --color-black-6: #9599A4;
        --color-black-7: #7A808D;
        --color-black-8: #6E737F;
        --color-black-9: #626671;
        --color-black-10: #CFD1D6; /* MODIFICADO  */
        --color-black-11: #313338;
        --color-black-12: #181A1C;
        --color-warning-red: #F4304A;
        --color-warning-red-1: #313338;
        --color-warning-red-2: #FEEAED;
        --color-warning-red-3: #F88392;
        --color-warning-red-4: #F6596E;
        --color-warning-red-5: #F4304A;
        --color-warning-red-6: #DC2B43;
        --color-warning-orange: #F87A26;
        --color-warning-orange-1: #FFF8F4;
        --color-warning-orange-2: #FEF2E9;
        --color-warning-orange-3: #FBAF7D;
        --color-warning-orange-4: #F99551;
        --color-warning-orange-5: #F87A26;
        --color-warning-orange-6: #DF6E22;
        --color-warning-yellow: #FAB830;
        --color-warning-yellow-1: #FFFBF5;
        --color-warning-yellow-2: #FFF8EA;
        --color-warning-yellow-3: #FCD483;
        --color-warning-yellow-4: #FBC659;
        --color-warning-yellow-5: #FAB830;
        --color-warning-yellow-6: #E1A52B;
        --color-warning-green: #24D066;
        --color-warning-green-1: #F4FDF7;
        --color-warning-green-2: #E9FAF0;
        --color-warning-green-3: #7CE3A3;
        --color-warning-green-4: #50D985;
        --color-warning-green-5: #24D066;
        --color-warning-green-6: #20BB5C;
        --color-white: #161b22;
    }
}

html,
body {
    font-family: 'Inter', sans-serif;
}

.ql-editor {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
}

div#editor-description .ql-container {
    height: 300px;
}

.active li{
    background-color: var(--color-primary-2);
}

.active li:hover{
    background-color: var(--color-primary-1);
}

#header-menu .active{
    border-bottom: 2.5px solid var(--color-primary);
    font-weight: bold;
}

#sidebar .active{
    font-weight: bold;
}
#sidebar .active div{
    stroke: var(--color-primary);
}

.active li:hover{
    background-color: var(--color-primary-2);
}

.dark {
    color-scheme: dark;
}

.dark .apexcharts-theme-light {
    color: black !important;
}

.dark .apexcharts-text {
fill: #fff;
}

.dark .apexcharts-tooltip,
.dark .apexcharts-tooltip-title {
    background-color: #333 !important;
    color: #fff !important;
    border: 1px solid #555 !important;
}
.dark .apexcharts-legend-text {
    color: #fff !important;
    /* border: 1px solid #555 !important; */
}

.dark #bookmarker img{
    background-color: white;
}

.dark .bond-detail a{
    color:#c9d1d9;
}

/* role="presentation" */
.dark #manageBookmarker [role="presentation"] {
    background-color: var(--color-primary-1) !important;
}

.dark .tox-editor-header {
    filter: invert(1);
}

.dark .tox-menu{
    filter: invert(1);
}
.dark .tox :not(svg):not(rect){
    color: black !important;
}

.dark .tox-statusbar {
    filter: invert(1);
}

#sidebar{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: thin;  /* Firefox */
}
#sidebar::-webkit-scrollbar { 
    display: auto;  /* Safari and Chrome */
}

/* top-left border-radius */
table tr:first-child th:first-child {
    border-top-left-radius: 6px;
}
  
/* top-right border-radius */
table tr:first-child th:last-child {
    border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}
  
/* bottom-right border-radius */
table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}

table tr th:first-child,
table tr td:first-child {
    border-left: none;
}

table tr th:last-child,
table tr td:last-child {
    border-right: none;
}

table tbody tr:last-child th,
table tbody tr:last-child td {
    border-bottom: none;
}

@media (max-width: 767px){
    #content{
        min-height: 100vh;
    }
}


.bond-html ul {
    margin-left: 24px;
	list-style: disc;

}

.guide-article p {
    padding-top: .75rem;
	padding-bottom: .75rem;
}

.dark .guide-article .text-black-12{
    color: #fff;
}

.theme-toggle{
    font-size: 35px;
    color: #0c275d;
}

.theme-toggle--toggled{
    color: #fff;
}

.dark #vili-logo{
    filter: brightness(0) invert(1);
}

.dark #hamburger-icon{
    filter: brightness(0) invert(1);
}

/* #bottom-arrow-animation {
    position: fixed;
    bottom: 30%;
    right: 25px;
    width: 0;
    height: 30px;
    border: 2px solid;
    border-radius: 2px;
  } */

#bottom-arrow-animation {
    position: fixed;
    bottom: 30%;
    right: 25px;
    transform: translate(-50%, -50%);
    transform: rotate(0deg);
    cursor: pointer;
}

#bottom-arrow-animation span {
    display: block;
    width: 2.5vw;
    height: 2.5vw;
    border-bottom: 5px solid #1E62E8;
    border-right: 5px solid #1E62E8;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

#bottom-arrow-animation span:nth-child(2) {
    animation-delay: -0.2s;
}

#bottom-arrow-animation span:nth-child(3) {
    animation-delay: -0.4s;
}

#subscriptionsCards::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#subscriptionsCards::-webkit-scrollbar{
	width: 12px;
	background-color: #F5F5F5;
}

#subscriptionsCards::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #1E62E8;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Relación de aspecto 16:9 */
    background-color: #2d2f31; /* Fondo negro */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.course-description p {
    padding-top: .40rem;
	padding-bottom: .40rem;
}

.course-description ul {
    margin-left: 24px;
	list-style: disc;
}

.dark .course-description .text-black-12{
    color: #fff;
}